import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Graphviz } from 'graphviz-react';
import React from 'react';
import { useSelector } from 'react-redux';

import { propertyDependencies as propertyDependenciesSelector } from '@/selectors';

import useDependencyGraphDOT from './buildDotGraph';
import classes from './DependencyGraphDialog.module.css';

interface DependencyGraphDialogProps {
	isOpen: boolean;
	onClose(): void;
}

const DependencyGraphDialog: React.FC<DependencyGraphDialogProps> = ({ isOpen, onClose }) => {
	const propertyDependencies = useSelector(propertyDependenciesSelector);

	const dot = useDependencyGraphDOT(propertyDependencies);

	return (
		<Dialog fullScreen onClose={onClose} open={isOpen}>
			<AppBar>
				<Toolbar>
					<IconButton edge="start" onClick={onClose} sx={{ float: 'right' }}>
						<CloseIcon />
					</IconButton>
					<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
						Property dependencies
					</Typography>
				</Toolbar>
			</AppBar>
			<Toolbar />
			<DialogContent sx={{ width: '550px' }}>
				{propertyDependencies.length === 0 && (
					<Alert severity="info">
						Currently there are no dependencies between properties yet. Once dependencies between
						properties have been added they will show up here.
					</Alert>
				)}
				{isOpen && (
					<Graphviz
						className={classes.dependencyGraphContainer}
						dot={dot}
						options={{ fit: false, height: null, useWorker: false, width: null }}
					/>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default DependencyGraphDialog;
